.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

h1 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.container {
    max-width: 1380px;
}

table tr.selected {
  background-color: lightgray
}

table tr.missing {
  background-color: lightpink
}

table tr.partial {
  background-color: rgb(238, 207, 149)
}

table td {
  vertical-align: top;
  align: left;
}